import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesElegg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Elegg guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ade.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Elegg guide & review</h1>
          <h2>A guide & review for Elegg in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>17/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="elegg" enablePopover />
        </div>
        <p>
          Your favorite gamer is making her debut! Elegg, dressed in her flashy
          voguish costume, is ready to leave a mark in everyone's life...
          devices, we mean. Part of the newly revealed Electric Shock team, she
          wanders the entertainment universe installing a revolutionary
          modification called Boom that amplifies the team's Distributed Damage.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Elegg can be played in two different playstyles. As a buffer or as a
          semi-DPS, or a little bit of both, depending on the circumstances.
          Although she is a support and doesn't seem to boast many offensive
          skills, MGs have a naturally high basic attack multiplier per second,
          which in turns helps her become a semi-DPS. Regardless, in both roles,
          she is played the same way. The difference is that, when serving as a
          DPS, she wants to maximize her uptime by spending less time reloading
          as that is a heavy detriment to MGs. This requires you to invest in
          her more, from upgrading skills to rerolling OL lines, which can be
          costly even for veterans. For more information, visit the PvE Team
          Building section. For now, let's analyze her skills.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates when entering battle. Affects all allies. </p>
          <p>
            When attacking an enemy projectile, damage dealt to that projectile
            ▲ 59.66 % continuously.{' '}
          </p>
          <p>
            ■ Activates after landing 100 normal attack(s). Affects the target
            and 2 surrounding enemy unit(s) if the target is in BOOM Install.{' '}
          </p>
          <p>Deals 91.03% of final ATK as Distributed Damage.</p>
        </blockquote>
        <p>
          Who doesn't want free increased damage against projectiles? Whenever
          Elegg is in the team, all allies now shatter certain projectiles
          faster, allowing them to waste less time shooting these pesky
          irritants. The buff value is substantial too, unlike the ones offered
          thus far in the game. This may be valuable against bosses that
          regularly toss destructibles such as Crystal Chamber and Land Eater.
          Besides that, this skill also allows her to deal more damage against
          enemies whenever they are affected by the debuff Boom Install, which
          is applied by her Burst Skill.
        </p>
        <Alert variant="primary">
          <p>
            On an average run against a single target with her being the only
            unit to use Burst Skill in B2 position, this would translate to
            around ~10% of her damage output.
          </p>
        </Alert>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates after landing 60 normal attack(s). Affects all allies if
            the target is in BOOM Install.{' '}
          </p>
          <p>ATK ▲ 13.09 % of caster's ATK for 5 sec. </p>
          <p>■ Activates when the target appears. Affects all allies. </p>
          <p>Fills Burst Gauge by 100%. Activates once per battle. </p>
        </blockquote>
        <p>
          Similar to what D offers, Elegg can immediately fill your Burst Gauge
          at the start of the battle to quickly get the gear rolling. This may
          not seem significant, but with that time saved, you may be able to
          squeeze more juice out of the final Full Burst, which is especially
          useful in Raids. Furthermore, whenever Elegg hits enemies affected by
          Boom Install a certain number of times, she also grants the team a
          moderate caster's ATK buff.
        </p>
        <Alert variant="primary">
          <p>
            In one Full Burst and without reloading in the final few seconds of
            the debuff, Elegg's ATK buff should last around ~14s because it
            refreshes just before the debuff expires. In certain teams, for
            example with Dorothy & Alice, you can try to quickly fill Burst
            Gauge within 4s so that Dorothy's Burst Skill can enjoy that
            increased ATK.
          </p>
        </Alert>
        <h6>Burst</h6>
        <blockquote>
          <p> ■ Affects all allies. </p>
          <p>Distributed Damage dealt ▲ 39.74 % for 10 sec. </p>
          <p>■ Affects the enemy nearest to the crosshair. </p>
          <p>Deals 79.2 % of final ATK as damage. </p>
          <p>BOOM Install: DEF ▼ 35.64 % for 10 sec. </p>
        </blockquote>
        <p>
          Deals negligible damage to the enemy closest to crosshair and
          administers Boom Install, reducing their DEF while boosting the team's
          Distributed Damage. The DEF reduction should amount to around ~9%
          bonus ATK in Solo Raid but varies across game modes. Meanwhile, the
          "undiluted" Distributed Damage buff is what makes this skill powerful.
          This niché augmentation couples well with Dorothy's Burst Skill
          (increasing its cap), SBS's Skill 1, and 2B's Burst Skill. However,
          among the three, Dorothy and SBS are the ones benefitting from this
          skill the most as 2B's Burst Skill is split into two types of damage.
          2B also profits more from Mast (and potentially Ade).
        </p>
        <Alert variant="primary">
          <p>
            When using Burst Skill and fighting against multiple mobs, make sure
            to manual Elegg and apply debuff on the tankiest enemy to benefit
            more out of it. This is because, if the enemy is killed, the debuff
            prematurely ends, and she cannot activate S1 & S2. When correctly
            used, expect &gt;20% DPS boost on Dorothy and SBS if Burst Skill is
            maxed.
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <h6>Campaign</h6>
        <p>
          An okay campaign unit which can be helpful for newer players due to MG
          having an unlimited reach without much DPS loss outside their optimal
          range. The Boom Install debuff expiring early due to the enemy being
          killed may, however, make her other buffs less lucrative.
        </p>
        <h6>Missilis Tower</h6>
        <p>
          Okay to use for non-meta havers but heavily outclassed by Liter, Tia,
          and Naga combo that has been circulating for the past few months.
          Also, she competes with Centi, which has inarguably proved her worth
          in Campaign before the duos arrived.
        </p>
        <h6>Raids</h6>
        <p>
          Should see some usage in Solo Raid and potentially Union Raid as both
          DPS and a support. Her being able to buff one of the strongest DPS
          in-game, namely SBS, is something too big to miss. Her compatibility
          with Dorothy is a rare sight too.
        </p>
        <h6>Simulation Overclock</h6>
        <p>
          DEF debuff from Install Boom may be handy for newer players struggling
          with damage in Simulation Overclock and should be more noticeable when
          Rapture's DEF buff is selected. Otherwise, unless there are absurd
          limitations, she is outshined by other B2.
        </p>
        <h6>PVP</h6>
        <p>
          Why are you thinking about this? Just no. No, the Burst Gauge
          immediate fill does NOT work in PvP. Don't think about it. Just don't.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As a support, you may want to OL her gear for the increased ATK stat
          to boost her buff's strength, mainly her Helmet and Gloves (and to a
          much lesser extent Body Armor). As a DPS, you want to go further by
          giving her the proper OL lines. Note that this is mandatory for her to
          work optimally. Since she will be paired with SAnis & Privaty as a
          DPS, you want to avoid reloading outside Full Burst at any cost. This
          requires you to have a minimum of 1–2 Max Ammo. After that, feel free
          to give her ATK & Elemental Damage, alongside some CRIT lines
          perchance?
        </p>
        <Alert variant="primary">
          <p>
            If Privaty's S1 is maxed, you can get away with Bastion and one line
            of Max Ammo. Otherwise, aim for two for extra leeway and equip
            Resilience. The &gt;109% Reload Speed threshold just cuts ~11
            frames, which are negligible. We do recommend spending as little
            rocks as possible, so if you can only get one line, then take the
            hit and move on!
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 4-7</strong>
          </li>
          <ul>
            <li>
              Projectile damage buff is nice to have, and levels also boost
              Elegg's DPS slightly. The impact is minimal regardless, so at most
              level it to 7.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4-7</strong>
          </li>
          <ul>
            <li>
              ATK buff is nice, but the value is quite low, so maybe it's not
              worth the time you grind in Simulation Room.
            </li>
          </ul>
          <li>
            <strong>Burst: 5-10</strong>
          </li>
          <ul>
            <li>
              Powerful buffs that help meta units shime brighter. While the
              scaling isn't that great, they are probably the most rewarding out
              of the three. Leave at 4 to save Purple Matz, 5 to save Blue Matz,
              or 7 to save Gold Matz.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          You need to make sure that Elegg takes less time to reload. As a
          support, this is trivial, but as a DPS, it creates a noticeable
          difference. In most teams, Bastion is obviously the better choice.
          However, in reload speed teams (SAnis + Privaty), you need to do some
          testing first. Either Resilience or Bastion would fit your situation
          better, depending on Privaty's S1 investment as well as the number of
          OL lines. Boot into Shooting Range without equipping any cube and do
          normal rotations. Just before Full Burst ends, force-reload Elegg.
          Now, keep shooting until you reach the next Full Burst. Here are
          several outcomes:
        </p>
        <ul>
          <li>
            If Elegg reloads outside Full Burst, use Bastion. If you still need
            to reload, get 1 Max Ammo line and try again. Or, you can try to
            time your reload so that it starts before Privaty's buff disappears
            and finishes after Privaty's debuff disappears. This way, Elegg
            reloads to her full ammo capacity.
          </li>
          <li>
            If Elegg does not reload outside Full Burst, you can use either
            cube, but Resilience can save you ~11 frames per reload by reaching
            the &gt;109% threshold. Do provide some leeway. For instance, having
            ±30 ammo when Full Burst begins is too tight. You can always get
            more Max Ammo lines, but it may not be efficient.
          </li>
          <li>
            For both cases above, you need to make sure that Elegg does not lose
            fire rate when reloading by making sure Privaty's S1 is invested
            well enough. No, it doesn't have to be &gt;100% or &gt;109%. Without
            sufficient reload speed, reloads will cause uncomfortable hiccups
            during Full Burst. If this occurs, use Resilience instead and reload
            outside Full Burst (still reload at the end of Full Burst), or
            obtain Max Ammo lines.
          </li>
          <li>
            That being said, we do recommend minimum 1–2 Max Ammo lines as a
            DPS.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <p>
          Elegg is a good roleplayer. She can be a DPS or a support, whichever
          route you want her to take. However, as a DPS, she is pretty much
          bound to SAnis & Privaty for the unlimited reload composition. Here
          are some examples:
        </p>
        <h5>Team #1: RATATATA DPS Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="elegg" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          An all-rounder with a FLEX spot that can be filled with any unit as
          required. Here, Elegg serves a DPS (and a support), and her goal is to
          shoot as much as possible. Insert Biscuit for cover repair & healing
          (note that Elegg is just a sitting duck if she doesn't use Burst
          Skill), or Scarlet/Guillotine for more damage, assuming that healing
          is unnecessary. Helm can be a good alternative too.
        </p>
        <Alert variant="primary">
          <p>
            If you are confused, going with Biscuit is always the safest choice.
            It's easy to run and features high survivability. Note that the
            Biscuit variant has been extensively tested to outdamage the
            Marciana Scarlet (as well as Poli & Mast) variant, which is
            incredible!
          </p>
        </Alert>
        <h5>Team #2: The Sword Pledge Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="elegg" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Elegg's main purpose here is to buff SBS's Skill 1, which constitutes
          her primary damage output. The FLEX can be Dorothy & Privaty (with
          Alice being the self-healer) or other units that would fit the
          situation best. You can also remove Alice if you want.
        </p>
        <h5>Team #3: Copium 2B Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="elegg" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="2b" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          As we mentioned earlier, Mast buffs 2B better than anyone else. If you
          don't have Mast, however, then feel free to use Elegg. Just don't hold
          too much expectation, okay?
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Elegg is a decent unit and definitely worth acquiring one copy, but
          after reading this review, we are sad to inform you that you will be
          one to ultimately decide your own destiny. We do recommend one copy if
          you are serious about Raids as she seems to be promising and should
          hold some value. Otherwise, it is okay to skip and put her in
          Wishlist. There might be better units incoming, so manage your
          resources wisely. You can always wait until the last day of the
          banner, when we will obtain official information about the next unit's
          kit.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Offers a unique Distributed Damage buff that can even
                    empower Dorothy.
                  </li>
                  <li>ATK buff that lasts a long time.</li>
                  <li>Notable damage boost to projectiles constantly.</li>
                  <li>Machine guns have unlimited reach.</li>
                  <li>Can both be DPS & support for meta units.</li>
                  <li>A voluptuous egg.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Reliant on Max Ammo as DPS.</li>
                  <li>Distributed Damage is a niché type of damage.</li>
                  <li>
                    Boom Install debuff expires prematurely if affected enemy is
                    killed and deactivates the trigger requirements for Skill 1
                    & 2.
                  </li>
                  <li>
                    Boom Install can be applied on the wrong enemy if Elegg is
                    not manualed when activating.
                  </li>
                  <li>
                    Addicted to Boom; addiction is never good, so tell us — how
                    much time do you spend in Nikke?.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box d">
            <h6>D</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesElegg;

export const Head: React.FC = () => (
  <Seo
    title="Elegg guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Elegg in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
